import { FC, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Container, Grid, Hidden, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { RedCirclePointedIcon } from 'svgs';
import { LinkButton } from 'components/atoms';
import { useMediaUp } from 'hooks';
import { Arrows } from 'components/structures/main/Slide';
import { ContentDescription } from 'components/structures/general';

interface SlideProps {
  title: string;
  image: string;
  imageMob?: string;
  upperTitle: string;
  buttons: Array<{ text: string; link: string; variant: 'text' | 'outlined' | 'contained' }>;
}

interface SliderProps {
  sliderList: Array<SlideProps>;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    sliderContent: {
      padding: theme.spacing(5, 0, 6),
      [theme.breakpoints.up('md')]: {
        maxHeight: 764,
        minHeight: 300,
        height: '100vh',
        padding: theme.spacing(17, 0, 6),
      },
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        zIndex: 1,
      },
    },
    imageWrapper: {
      [theme.breakpoints.up('sm')]: {
        position: 'relative',
        '& svg': {
          position: 'absolute',
          bottom: theme.spacing(24),
          left: theme.spacing(-13),
        },
      },
    },
    image: {
      maxHeight: 460,
      minHeight: 300,
      objectFit: 'cover',
      [theme.breakpoints.up('md')]: {
        maxHeight: 764,
        objectFit: 'cover',
        height: '100vh',
      },
    },
    title: {
      marginBottom: theme.spacing(8),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(10),
      },
    },
    animationFade: {
      transform: `translateY(-15px)`,
      opacity: 0,
      transition: 'all .6s ease-out',
    },
    runAnimation: {
      transform: `translateY(-15px)`,
      opacity: 0,
    },
    disableAnimation: {
      transform: `translateX(0)`,
      opacity: 1,
    },
    resetStyle: {
      [theme.breakpoints.down('sm')]: {
        all: 'unset',
      },
    },
    slider: {
      [theme.breakpoints.up('md')]: {
        transform: 'translateX(3px)',
      },
    },
    slideWrapper: {
      padding: theme.spacing(0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 0.6),
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 0.6),
      },
    },
    resetStyleSecond: {
      [theme.breakpoints.up('md')]: {
        all: 'unset',
      },
    },
    gridRight: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: 0,
      },
    },
  });
});

const Slider: FC<SliderProps> = ({ sliderList }) => {
  const isSmallUp = useMediaUp('sm');
  const [slide, setSlide] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [runAnimation, setRunAnimation] = useState(true);
  const classes = useStyles();

  const arrowHandlers = {
    onPrev: null,
    onNext: null,
  };

  const handleChange = (selectedIndex) => {
    if (!loading && slide !== selectedIndex) {
      setLoading(true);
      setRunAnimation(true);
      setSlide(selectedIndex);
      setTimeout(() => {
        setTextIndex(selectedIndex);
        setRunAnimation(false);
        setLoading(false);
      }, 601);
    }
  };

  useEffect(() => {
    const timerAnimation = setTimeout(() => {
      setRunAnimation(false);
      setLoading(false);
    }, 100);
    return () => {
      clearTimeout(timerAnimation);
    };
  }, []);

  return (
    <>
      {!!sliderList.length && (
        // <SlideContent title={title} text={text} key={index} image={image} />
        <Container maxWidth={false} fixed className={classes.resetStyle}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={6} lg={12}>
              <Container maxWidth={false} fixed className={classes.resetStyleSecond}>
                <Grid container justifyContent="space-between" spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <Grid container justifyContent="space-between" direction="column" className={classes.sliderContent}>
                      <Grid item>
                        <ContentDescription
                          upperTitle={{
                            text: sliderList[textIndex].upperTitle,
                            className: clsx(
                              classes.animationFade,
                              runAnimation ? classes.runAnimation : classes.disableAnimation,
                            ),
                          }}
                          title={{
                            text: sliderList[textIndex].title,
                            variant: 'h1',
                            className: clsx(
                              classes.title,
                              classes.animationFade,
                              runAnimation ? classes.runAnimation : classes.disableAnimation,
                            ),
                          }}
                        />
                        <Grid
                          container
                          spacing={isSmallUp ? 6 : 4}
                          className={clsx(classes.animationFade, runAnimation ? classes.runAnimation : classes.disableAnimation)}
                        >
                          {sliderList[textIndex].buttons.map((button, idx) => (
                            <Grid item key={`${button.text}-${button.link}-${button.variant}-${idx}`}>
                              <LinkButton href={button.link} variant={button.variant} color="primary">
                                {button.text}
                              </LinkButton>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item>
                        {sliderList.length > 1 && (
                          <Arrows
                            totalSlides={Array.from(sliderList.keys())}
                            onClickIndicator={handleChange}
                            currentSlide={slide}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Hidden mdDown>
                    <Grid item xs={12} md={6} lg={5}>
                      <div className={classes.imageWrapper}>
                        <Carousel
                          autoPlay
                          showStatus={false}
                          showArrows={false}
                          showIndicators={false}
                          showThumbs={false}
                          swipeable={false}
                          interval={5000}
                          onChange={handleChange}
                          selectedItem={slide}
                          transitionTime={1200}
                          infiniteLoop
                          renderArrowPrev={(onClick) => {
                            arrowHandlers.onPrev = onClick;
                            return null;
                          }}
                          renderArrowNext={(onClick) => {
                            arrowHandlers.onNext = onClick;
                            return null;
                          }}
                        >
                          {sliderList.map(({ image }, index) => (
                            <div key={'image pc-' + index} className={classes.slideWrapper}>
                              <img className={classes.image} src={image} alt={image} />
                            </div>
                          ))}
                        </Carousel>
                        <Hidden smDown>
                          <RedCirclePointedIcon />
                        </Hidden>
                      </div>
                    </Grid>
                  </Hidden>
                </Grid>
              </Container>
            </Grid>
            <Hidden lgUp>
              <Grid item md={5} lg={5} className={classes.gridRight}>
                <div className={classes.imageWrapper}>
                  <Carousel
                    className={classes.slider}
                    autoPlay
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    swipeable={false}
                    interval={5000}
                    onChange={handleChange}
                    selectedItem={slide}
                    transitionTime={1200}
                    infiniteLoop
                    renderArrowPrev={(onClick) => {
                      arrowHandlers.onPrev = onClick;
                      return null;
                    }}
                    renderArrowNext={(onClick) => {
                      arrowHandlers.onNext = onClick;
                      return null;
                    }}
                  >
                    {sliderList.map(({ imageMob }, index) => (
                      <div key={'image-' + index} className={classes.slideWrapper}>
                        <img className={classes.image} src={imageMob} alt={imageMob} />
                      </div>
                    ))}
                  </Carousel>
                  <Hidden smDown>
                    <RedCirclePointedIcon />
                  </Hidden>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default Slider;
